import Vue from "vue";
import api from "../api";
import dayjs from "dayjs";
import { EXPENSES_TABLE_FIRST_COLUMN_WIDTH } from "@/assets/constants.js";

const expensesStore = {
  namespaced: true,
  state: {
    expensesList: [],
    currentMonthExpensesList: [],
    expensesViewList: [],
    projectsList: [],
    profilesList: [],
    activeProjectIdsList: [],
    activeProfilesList: [],
    newTask: null,
    patchTask: null,
    deleteTask: null,
    newTimeEntries: null,
    patchTimeEntries: null,
    deleteTimeEntries: null,
    error: "",
    focusedColumnNumber: -2,
    focusCreatedInput: { timeEntriesId: 0 },
    focusedCellPosition: {},
    selectedInterval: { amount: 0 },
    currentInterval: null,
    closedRows: [],
    tooltipInfo: {
      data: "",
      sourceElement: null,
      position: {
        top: 0,
        left: 0,
      },
    },
    readonly: false,
    viewedMonthOfYear: "",
    isEditableInterval: true,
    expensesTimeFormat: "",
    expensesHiddenTasks: [],
    isHiddenRowsVisible: false,
    isTimerActive: false,
    timerPcDayId: null,
    timer: {
      taskId: null,
      taskName: "",
      currentDay: null,
      timeEntryId: null,
      prevDuration: null,
      startTime: null,
      userId: null,
      timerIntervalId: null,
      timerDuration: 0,
    },
    firstColumnWidth: 300,
    resizeX: 0,
    resizeStatus: "end",
    sizeUpdateTime: 0,
    tableType: "expenses",
  },
  getters: {
    getReadonly: (state) => {
      return state.readonly;
    },
    getExpensesList: (state) => {
      return state.expensesList;
    },
    getExpensesViewList: (state) => {
      return state.expensesViewList;
    },
    getCurrentTrackedDays: (state) => {
      const currentExpensesList = state.readonly
        ? state.expensesViewList
        : state.expensesList;

      if (currentExpensesList.length) {
        const trackedDays = [];

        currentExpensesList.forEach((expensesItem) => {
          const customers = expensesItem.customers
            ? expensesItem.customers
            : [expensesItem];
          customers.forEach((customer) => {
            customer.projects.forEach((project) => {
              project.assignments?.forEach((assignment) => {
                assignment.sub_assignments.forEach((subAssignment) => {
                  const currentProfileId = subAssignment.profile.id;
                  subAssignment.tasks.forEach((task) => {
                    task.time_entries.forEach((timeEntriesItem) => {
                      const trackDay = trackedDays.find(
                        (trackedDay) =>
                          trackedDay.dayId === timeEntriesItem.pc_day.id
                      );
                      if (trackDay) {
                        trackDay.actual_hours += timeEntriesItem.actual_hours;
                        const currentProfile = trackDay.profileIds.find(
                          ({ profile }) => profile === currentProfileId
                        );
                        if (!currentProfile) {
                          trackDay.profileIds.push({
                            profile: currentProfileId,
                            actual_hours: timeEntriesItem.actual_hours,
                          });
                        } else {
                          currentProfile.actual_hours +=
                            timeEntriesItem.actual_hours;
                        }
                      } else {
                        trackedDays.push({
                          actual_hours: timeEntriesItem.actual_hours,
                          number: timeEntriesItem.pc_day.number,
                          dayId: timeEntriesItem.pc_day.id,
                          profileIds: [
                            {
                              profile: currentProfileId,
                              actual_hours: timeEntriesItem.actual_hours,
                            },
                          ],
                        });
                      }
                    });
                  });
                });
              });
            });
          });
        });

        console.log("currentTrackedDays: ", trackedDays);
        return trackedDays;
      } else {
        return [];
      }
    },
    getCurrentTooMuchTrackedDays: (state, getters) => {
      const currentExpensesList = state.readonly
        ? state.expensesViewList
        : state.expensesList;

      if (currentExpensesList.length) {
        const currentTooMuchTrackedDays = [];

        getters.getCurrentTrackedDays.forEach((trackedDay) => {
          if (
            trackedDay.profileIds.find(({ actual_hours }) => actual_hours > 24)
          ) {
            currentTooMuchTrackedDays.push(trackedDay);
          }
        });
        console.log("currentTooMuchTrackedDays: ", currentTooMuchTrackedDays);
        return currentTooMuchTrackedDays;
      } else {
        return [];
      }
    },
    getProjectsList: (state) => {
      return state.projectsList;
    },
    getProfilesList: (state) => {
      return state.profilesList;
    },
    getActiveProjectIdsList: (state) => {
      return state.activeProjectIdsList;
    },
    getActiveProfilesList: (state) => {
      return state.activeProfilesList;
    },
    getFocusCreatedInput: (state) => {
      return state.focusCreatedInput;
    },
    getFocusedCellPosition: (state) => {
      return state.focusedCellPosition[state.viewedMonthOfYear] || {};
    },
    getSelectedInterval: (state) => {
      return state.selectedInterval;
    },
    getClosedRows: (state) => {
      return state.closedRows;
    },
    getTooltipInfo: (state) => {
      return state.tooltipInfo;
    },
    getExpensesTimeFormat: (state) => {
      return state.expensesTimeFormat;
    },
    getExpensesHiddenTasks: (state) => {
      return state.expensesHiddenTasks;
    },
    getHiddenRowsVisibility: (state) => {
      return state.isHiddenRowsVisible;
    },
    getTimer: (state) => {
      return state.timer;
    },
    getCurrentMonthExpensesList: (state) => {
      return state.currentMonthExpensesList;
    },
  },
  mutations: {
    resizeStart(state) {
      state.resizeStatus = "start";
      state.resizeX = state.firstColumnWidth;
    },
    resizeEnd(state) {
      state.sizeUpdateTime = Date.now();
      state.resizeStatus = "end";

      const width = state.resizeX;
      if (width && typeof width === "number") {
        state.firstColumnWidth = width;
        window.localStorage.setItem(EXPENSES_TABLE_FIRST_COLUMN_WIDTH, width);
      }
    },
    setResizeX(state, width) {
      if (300 <= width && width <= 600) {
        state.resizeX = width;
      }
    },
    restoreFirstColumnWidth(state) {
      const width = window.localStorage.getItem(
        EXPENSES_TABLE_FIRST_COLUMN_WIDTH
      );
      state.firstColumnWidth = parseInt(width) || 300;
    },
    setEditableIntervalFlag(state, isTrue) {
      state.isEditableInterval = isTrue;
    },
    setViewedMonthOfYear(state, date) {
      state.viewedMonthOfYear = date;
    },
    setReadonly(state, value) {
      const monthId = state.viewedMonthOfYear;
      if (value && state.focusedCellPosition[monthId]) {
        state.focusedCellPosition[monthId] = {
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          numberOfColumn: -3,
        };
      }
      state.readonly = value;
    },
    resetCellRef(state) {
      const monthId = state.viewedMonthOfYear;
      if (state.focusedCellPosition[monthId]) {
        state.focusedCellPosition[monthId].cellRef = null;
      }
    },
    setExpensesList(state, data) {
      const result = data.results;

      if (!data.isOnlyCurrent) {
        state.expensesList = result;
      } else {
        state.currentMonthExpensesList = result;
      }
    },
    setExpensesViewList(state, data) {
      state.expensesViewList = data.results;
    },
    setProjectsList(state, value) {
      state.activeProjectIdsList = [];
      state.projectsList = value;
    },
    setProfilesList(state, value) {
      const activeProfilesList = [];
      const formattedValue = value.map((profile) => {
        const firstName = profile.first_name;
        const formattedFirstName =
          firstName.charAt(0).toUpperCase() + firstName.slice(1);
        activeProfilesList.push(profile.id);
        return {
          ...profile,
          name: `${profile.last_name} ${formattedFirstName}`,
        };
      });

      state.profilesList = formattedValue;
      state.activeProfilesList = [];
    },
    setActiveProjectIdsList(state, value) {
      state.activeProjectIdsList = value;
    },
    setActiveProfilesList(state, value) {
      state.activeProfilesList = value;
    },
    createNewTask(state, value) {
      state.expensesList.forEach((expensesItem) => {
        expensesItem.projects.forEach((project) => {
          project.assignments.forEach((assignment) => {
            assignment.sub_assignments.forEach((sub_assignment) => {
              if (sub_assignment.id === value.sub_assignment) {
                const newTask = {
                  id: value.id,
                  month_sum_actual_hours: 0,
                  name: value.name,
                  sum_actual_hours: null,
                  time_entries: [],
                };
                sub_assignment.tasks.push(newTask);
              }
              return sub_assignment;
            });
          });
        });
      });
    },
    patchTask(state, { id, newName }) {
      state.expensesList.forEach((expensesItem) => {
        expensesItem.projects.forEach((project) => {
          project.assignments.forEach((assignment) => {
            assignment.sub_assignments.forEach((sub_assignment) => {
              const patchedTask = sub_assignment.tasks.find((task) => {
                return task.id === id;
              });
              if (patchedTask) {
                patchedTask.name = newName;
              }
            });
          });
        });
      });
    },
    deleteTask(state, value) {
      state.expensesList.forEach((expensesItem) => {
        expensesItem.projects.forEach((project) => {
          project.assignments.forEach((assignment) => {
            assignment.sub_assignments.forEach((sub_assignment) => {
              sub_assignment.tasks.forEach((task, ind) => {
                if (task.id === value.id) {
                  const taskTime = task.time_entries.reduce(
                    (total, item) => total + item.actual_hours,
                    0
                  );
                  expensesItem.sum_actual_hours -= taskTime;
                  assignment.sum_actual_hours -= taskTime;
                  project.sum_actual_hours -= taskTime;
                  sub_assignment.tasks.splice(ind, 1);
                }
              });
            });
          });
        });
      });
    },
    createNewTimeEntries(state, value) {
      const taskId = value.task;
      const actual_hours = value.actual_hours;
      const pcDayId = value.pc_day;
      const dayNumber = value.dayNumber;
      const timeEntriesId = value.id;
      const expensesListCopy = [...state.expensesList];

      const currentExpensesItem = expensesListCopy.find((expensesItem) => {
        const currentProject = expensesItem.projects.find((project) => {
          const currentAssignment = project.assignments.find((assignment) => {
            return assignment.sub_assignments.find((sub_assignment) => {
              return sub_assignment.tasks.find((task) => {
                if (task.id === taskId) {
                  const newTimeEntriesItem = {
                    actual_hours,
                    id: timeEntriesId,
                    pc_day: {
                      number: dayNumber,
                      id: pcDayId,
                    },
                  };
                  task.time_entries.push(newTimeEntriesItem);
                  return true;
                } else {
                  return false;
                }
              });
            });
          });
          if (currentAssignment) {
            currentAssignment.sum_actual_hours += value.actual_hours;
            return currentAssignment;
          } else {
            return false;
          }
        });
        if (currentProject) {
          currentProject.sum_actual_hours += value.actual_hours;
          return currentProject;
        } else {
          return false;
        }
      });
      currentExpensesItem.sum_actual_hours += value.actual_hours;
      state.expensesList = expensesListCopy;
    },
    patchTimeEntries(state, { actual_hours, id }) {
      let oldTimeEntriesItemActualHours = 0;
      const expensesListCopy = [...state.expensesList];

      const currentExpensesItem = expensesListCopy.find((expensesItem) => {
        const currentProject = expensesItem.projects.find((project) => {
          const currentAssignment = project.assignments.find((assignment) => {
            return assignment.sub_assignments.find((sub_assignment) => {
              return sub_assignment.tasks.find((task) => {
                return task.time_entries.find((timeEntriesItem) => {
                  if (timeEntriesItem.id === id) {
                    oldTimeEntriesItemActualHours =
                      timeEntriesItem.actual_hours;
                    timeEntriesItem.actual_hours = actual_hours;
                    return true;
                  } else {
                    return false;
                  }
                });
              });
            });
          });
          if (currentAssignment) {
            currentAssignment.sum_actual_hours +=
              actual_hours - oldTimeEntriesItemActualHours;
            return currentAssignment;
          } else {
            return false;
          }
        });
        if (currentProject) {
          currentProject.sum_actual_hours +=
            actual_hours - oldTimeEntriesItemActualHours;
          return true;
        } else {
          return false;
        }
      });
      console.log(currentExpensesItem);
      currentExpensesItem.sum_actual_hours +=
        actual_hours - oldTimeEntriesItemActualHours;
      state.expensesList = expensesListCopy;
    },
    deleteTimeEntries(state, { id }) {
      let deleteTimeEntriesActualHours = 0;
      let deleteTimeEntriesInd = 0;
      let currentTask = [];
      const expensesListCopy = [...state.expensesList];

      const currentExpensesItem = expensesListCopy.find((expensesItem) => {
        const currentProject = expensesItem.projects.find((project) => {
          const currentAssignment = project.assignments.find((assignment) => {
            return assignment.sub_assignments.find((sub_assignment) => {
              currentTask = sub_assignment.tasks.find((task) => {
                return task.time_entries.find((timeEntriesItem, ind) => {
                  if (timeEntriesItem.id === id) {
                    deleteTimeEntriesActualHours = timeEntriesItem.actual_hours;
                    deleteTimeEntriesInd = ind;
                    return true;
                  } else {
                    return false;
                  }
                });
              });
              return currentTask;
            });
          });
          if (currentAssignment) {
            currentAssignment.sum_actual_hours -= deleteTimeEntriesActualHours;
            return currentAssignment;
          } else {
            return false;
          }
        });
        if (currentProject) {
          currentProject.sum_actual_hours -= deleteTimeEntriesActualHours;
          return currentProject;
        } else {
          return false;
        }
      });
      currentExpensesItem.sum_actual_hours -= deleteTimeEntriesActualHours;
      currentTask.time_entries.splice(deleteTimeEntriesInd, 1);
      console.log("expList: ", state.expensesList);
      state.expensesList = expensesListCopy;
    },
    setExpensesError(state, value) {
      state.error = value;
    },
    setFocusedColumnNumber(state, value) {
      if (value === 0 || value === 1) {
        return;
      }
      state.focusedColumnNumber = value;
    },
    setFocusCreatedInput(state, value) {
      state.focusCreatedInput = value;
    },
    setFocusedCellPosition(state, data) {
      const monthId = state.viewedMonthOfYear;
      if (state.focusedCellPosition[monthId]) {
        state.focusedCellPosition[monthId] = data;
      } else {
        Vue.set(state.focusedCellPosition, monthId, data);
      }
    },
    setSelectedInterval(state, value) {
      state.selectedInterval = value;
    },
    setCurrentInterval(state, value) {
      state.currentInterval = value;
    },
    setClosedRows(state, value) {
      state.closedRows = value;
    },
    setTooltipInfo(state, value) {
      state.tooltipInfo = value;
    },
    setExpensesTimeFormat(state, value) {
      state.expensesTimeFormat = value;
    },
    setExpensesHiddenTasks(state, value) {
      state.expensesHiddenTasks = value;
    },
    setHiddenRowsVisibility(state, value) {
      state.isHiddenRowsVisible = value;
    },
    setIsTimerActive(state, value) {
      state.isTimerActive = value;
    },
    setTimerIntervalId(state, value) {
      state.timer.timerIntervalId = value;
    },
    setTimerDuration(state, value) {
      state.timer.timerDuration = value;
    },
    setTimer(state, value) {
      state.timer = value
        ? value
        : {
            taskId: null,
            taskName: "",
            currentDay: null,
          };
    },
    setTimerPcDayId(state, value) {
      state.timerPcDayId = value;
    },
    setTimerPrevDuration(state, value) {
      state.timer.prevDuration = value;
    },
    setTimerTimeEntryId(state, value) {
      state.timer.timeEntryId = value;
    },
  },
  actions: {
    async loadExpensesList({ commit, dispatch }, params = {}) {
      try {
        const { results } = await api.expenses.list(params);
        commit("setExpensesList", { results, ...params });
      } catch (e) {
        // dispatch("noAuth", e, { root: true });
        commit("setExpensesError", "нет доступа к таблице");
        dispatch(
          "messagesStore/setMessage",
          {
            title: `${e.code} Получение трудозатрат`,
            message: `Ошибка сети. Не удалось получить данные.\n${e.message}`,
          },
          { root: true }
        );
      }
    },
    async loadExpensesViewList({ commit, dispatch }, params = {}) {
      try {
        if (!params.projects && !params.profiles) {
          commit("setExpensesViewList", { results: [] });
          return;
        }
        const { results } = await api.expenses.viewList(params);
        commit("setExpensesViewList", { results, isPart: params.isPart });
      } catch (e) {
        // dispatch("noAuth", e, { root: true });
        commit("setExpensesError", "нет доступа к таблице");
        dispatch(
          "messagesStore/setMessage",
          {
            title: `${e.code} Получение данных по трудозатратам на проекты`,
            message: `Ошибка сети. Не удалось получить данные.\n${e.message}`,
          },
          { root: true }
        );
      }
    },
    async loadExpensesEmployeesList({ commit, dispatch }, params = {}) {
      try {
        if (!params.since || !params.until || !params.profiles) {
          commit("setExpensesViewList", { results: [] });
          return;
        }
        const { results } = await api.expenses.employeesList(params);
        commit("setExpensesViewList", { results, isPart: params.isPart });
      } catch (e) {
        // dispatch("noAuth", e, { root: true });
        commit("setExpensesError", "нет доступа к таблице");
        dispatch(
          "messagesStore/setMessage",
          {
            title: `${e.code} Получение трудозатрат сотрудников на проекты`,
            message: `Ошибка сети. Не удалось получить данные.\n${e.message}`,
          },
          { root: true }
        );
      }
    },
    async loadProjectsViewList({ commit, dispatch }, params = {}) {
      try {
        const { results } = await api.expenses.projectsList(params);
        commit("setProjectsList", results);
      } catch (e) {
        // dispatch("noAuth", e, { root: true });
        commit("setExpensesError", "нет доступа к таблице");
        dispatch(
          "messagesStore/setMessage",
          {
            title: `${e.code} Получение списка проектов для сотрудника`,
            message: `Ошибка сети. Не удалось получить данные.\n${e.message}`,
          },
          { root: true }
        );
      }
    },
    async loadStaffViewList({ commit, dispatch }, params = {}) {
      try {
        const { results } = await api.expenses.staffList(params);
        commit("setProfilesList", results);
      } catch (e) {
        // dispatch("noAuth", e, { root: true });
        commit("setExpensesError", "нет доступа к таблице");
        dispatch(
          "messagesStore/setMessage",
          {
            title: `${e.code} Получение списка сотрудников по проектам`,
            message: `Ошибка сети. Не удалось получить данные.\n${e.message}`,
          },
          { root: true }
        );
      }
    },
    async createNewTask({ commit, dispatch }, { name, sub_assignment }) {
      try {
        const response = await api.expenses.createNewTask({
          name,
          sub_assignment,
        });
        console.log("createTask: ", response);
        commit("createNewTask", response);
        return { response, isSuccess: true };
      } catch (e) {
        console.log(e);
        dispatch(
          "messagesStore/setMessage",
          {
            title: `${e.code} Создание новой задачи`,
            message: `Ошибка сети. Не удалось создать задачу.\n${e.message}`,
          },
          { root: true }
        );
        return { isSuccess: false, details: e.details };
      }
    },
    async patchTask({ commit, dispatch }, { name, id }) {
      try {
        const response = await api.expenses.patchTask({ name, id });
        console.log("patchTask: ", response);
        commit("patchTask", { newName: name, id });
        return { isSuccess: true };
      } catch (e) {
        // dispatch("noAuth", e, { root: true });
        console.log(e);
        // commit("setExpensesError", "ошибка создания задачи");
        dispatch(
          "messagesStore/setMessage",
          {
            title: `${e.code} Обновление данных задачи`,
            message: `Ошибка сети. Данные не сохранены.\n${e.message}`,
          },
          { root: true }
        );
      }
    },
    async deleteTask({ commit, dispatch }, { id }) {
      try {
        const response = await api.expenses.deleteTask({ id });
        console.log("deleteTask: ", response);
        commit("deleteTask", { id });
      } catch (e) {
        // dispatch("noAuth", e, { root: true });
        console.log(e);
        // commit("setExpensesError", "ошибка создания задачи");
        dispatch(
          "messagesStore/setMessage",
          {
            title: `${e.code} Удаление задачи`,
            message: `Ошибка сети. Не удалось удалить задачу.\n${e.message}`,
          },
          { root: true }
        );
      }
    },
    async createNewTimeEntries(
      { commit, dispatch },
      { actual_hours, pc_day, taskId, dayNumber, isCurrentMonthActive = false }
    ) {
      try {
        const response = await api.expenses.createTimeEntries({
          actual_hours,
          pc_day,
          task: taskId,
        });
        response.dayNumber = dayNumber;

        if (isCurrentMonthActive) {
          commit("createNewTimeEntries", response);
        }

        return response;
      } catch (e) {
        // dispatch("noAuth", e, { root: true });
        console.log(e);
        // commit("setExpensesError", "ошибка создания задачи");
        dispatch(
          "messagesStore/setMessage",
          {
            title: `${e.code} Создание новой рабочей единицы`,
            message: `Ошибка сети. Не удалось сохранить данные.\n${e.message}`,
          },
          { root: true }
        );
      }
    },
    async patchTimeEntries({ commit, dispatch }, { actual_hours, id }) {
      try {
        const response = await api.expenses.patchTimeEntries({
          actual_hours,
          id,
        });
        console.log("patchTimeEntries: ", response);
        commit("patchTimeEntries", { actual_hours, id });
        return response;
      } catch (e) {
        // dispatch("noAuth", e, { root: true });
        console.log(e);
        dispatch(
          "messagesStore/setMessage",
          {
            title: `${e.code} Изменение рабочей единицы`,
            message: `Ошибка сети. Не удалось сохранить данные.\n${e.message}`,
          },
          { root: true }
        );
        return false;
        // commit("setExpensesError", "ошибка создания задачи");
      }
    },
    async deleteTimeEntries({ commit, dispatch }, { id }) {
      try {
        const response = await api.expenses.deleteTimeEntries({ id });
        commit("deleteTimeEntries", { id });
        return response;
      } catch (e) {
        // dispatch("noAuth", e, { root: true });
        console.log(e);
        // commit("setExpensesError", "ошибка создания задачи");
        dispatch(
          "messagesStore/setMessage",
          {
            title: `${e.code} Удаление рабочей единицы`,
            message: `Ошибка сети. Не удалось удалить данные.\n${e.message}`,
          },
          { root: true }
        );
      }
    },
    startTimer({ commit, state }, payload) {
      commit("setIsTimerActive", true);
      let timerUpdatesCount = 0;
      const timerInterval = setInterval(() => {
        if (timerUpdatesCount === 9) {
          const trackedTime = dayjs().diff(state.timer.startTime, "second");
          commit("setTimerDuration", trackedTime);
          timerUpdatesCount = 0;
        } else {
          commit("setTimerDuration", state.timer.timerDuration + 1);
          timerUpdatesCount += 1;
        }
      }, 1000);
      commit("setTimer", {
        ...state.timer,
        ...payload,
        timerIntervalId: timerInterval,
      });

      localStorage.setItem("EXPENSES_TIMER_DATA", JSON.stringify(state.timer));
    },
    stopTimer({ commit, state }) {
      clearInterval(state.timer.timerIntervalId);
      commit("setTimer", {
        taskId: null,
        taskName: "",
        currentDay: null,
        timeEntryId: null,
        prevDuration: null,
        startTime: null,
        userId: null,
        timerIntervalId: null,
        timerDuration: 0,
      });
      window.localStorage.removeItem("EXPENSES_TIMER_DATA");
    },
  },
};

export default expensesStore;
